<template>
  <div class="v-row">
    <div class="v-col-md-12 v-col-12">
      <base-card v-if="source">
        <iframe-component          
          :src="source"
          frameborder="0"
          gesture="media"
          allow="encrypted-media"
          sandbox="allow-same-origin allow-scripts"
        ></iframe-component>
      </base-card>
    </div>
  </div>
</template>
<script>
import BaseCard from "@/components/BaseCard.vue";
import IframeComponent from "@/components/IframeComponent";
import { useMenuStore } from "@/stores/menuStore";
import { mapState, mapActions } from "pinia";
export default {
  name: "External",
  components: {
    IframeComponent,
    BaseCard
  },
  data() {
    return {
      menuItemId: null,
      dataloading: false,
    };
  },
  methods: {
    ...mapActions(useMenuStore, ["findMenuItemByProperty"]),
    onLoad() {
      "Onload";
    },
  },
  beforeRouteUpdate(to) {
    if (to?.params) {
      this.menuItemId = this.$route.params.id;
    }
    console.log("beforeRouteUpdate", to);
  },
  computed: {
    ...mapState(useMenuStore, ["menu"]),
    currentMenuItem() {
      if (this.menu?.length > 0 && this.menuItemId) {
        let menuItem = this.findMenuItemByProperty("id", this.menuItemId);
        return menuItem ? menuItem : null;
      }
      return null;
    },
    source() {
      if (this.currentMenuItem) {
        return this.currentMenuItem?.href;
        // let link = this.currentMenuItem?.properties?.link;
        // let params ="";
        // let keys = Object.keys(this.currentMenuItem?.properties?.params);
        // keys.forEach(element => {
        //   if(this.currentMenuItem?.properties?.params[element] == "current_user_token"){
        //     params = params + `${element}=${this.token}&`
        //   }
        //   else {
        //     params = params + `${element}=${this.currentMenuItem?.properties?.params[element]}&`
        //   }
        // });
        //  return `${link}?${params}`
      }
      return null;
    },
  },
  mounted() {
    if (this.$route?.params?.id.length > 0) {
      this.menuItemId = this.$route.params.id;
    }
  },
};
</script>
<style></style>
